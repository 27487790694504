<template>
    <div>

    </div>
</template>

<script>
export default {
    name: 'Hits',
    data: () => {
        return {
            loading: false,
            logs: []
        }
    },
    async beforeMount() {
		await this.data()
    },
    methods: {
        async data() {
            this.loading = true

            var [err, response] = await this.$orm.queryRAW({
                query: `
                    query GetLogs {
                        stats_live(order_by: {date: desc}, limit: 20) {
                            city
                            country
                            date
                            device_model
                            device_os
                            device_type
                            device_vendor
                            language
                            link {
                                id
                                name
                                short_link
                            }
                        }
                    }
                `
            })
            if(err) {
                console.error('/views/hits', 'methods.data', '$orm.queryRAW', err)
                return $.NotificationApp.send("Error", "Can't get stats", "top-right", "", "error")
            }
            console.debug('/views/hits', 'methods.data', '$orm.queryRAW', response)

            this.logs = response.stats_live || []

            setTimeout(() => {
                this.loading = false
            }, 250)
        }
    }
}
</script>

<style>

</style>